import React from "react";
import { Layer, Rect } from "react-konva";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  getActiveGlobalSheet,
  getActivePageSize,
  getCanvasHeight,
  getCanvasWidth,
  getConfigStageZoom,
  getGlobalCoordLayer,
  getSizeGlobalSheet,
  updateActiveIDKonva,
  updateGlobalCoordLayer,
  updateStageZoom,
} from "../../../../core/store/konva-editor/konva-editorSlice";
import { breakpoints } from "../../../../constants/breakpoints";

interface IOwnProps {
  refLayer: any;
  children: any;
}
const LayerEditor: React.FC<IOwnProps> = ({ refLayer, children }) => {
  const activePageSize = useAppSelector(getActivePageSize); // Tamaño de la página activa

  const activeSheet = useAppSelector(getActiveGlobalSheet);
  const canvasWidth = useAppSelector(getCanvasWidth);
  const canvasHeight = useAppSelector(getCanvasHeight);
  const globalCoordLayer = useAppSelector(getGlobalCoordLayer);
  const globalValueZoom = useAppSelector(getConfigStageZoom);

  const dispatch = useAppDispatch();

  const calculateDimensions = () => {
    if (!activePageSize || activePageSize.length < 2) {
      return { width: 210, height: 297 }; // Valores por defecto si no se encuentra el tamaño
    }
    const heightCalc = window.innerHeight / activePageSize[1] - 0.3;
    const width = activePageSize[0] * heightCalc;
    const height = activePageSize[1] * heightCalc;
    return { width, height };
  };

  const [layerPDFDimensions, setLayerPDFDimensions] =
    React.useState(calculateDimensions);

  React.useEffect(() => {
    // const valueReduce = window.innerWidth < breakpoints.tabletSValue ? 0.8 : 0.3;
    // const heightCalc = window.innerHeight / sizeGlobalSheet[activeSheet - 1][1] - valueReduce;

    // dispatch(
    //   updateGlobalCoordLayer({
    //     x: (canvasWidth - sizeGlobalSheet[activeSheet - 1][0]) / 2,
    //     y: (canvasHeight - sizeGlobalSheet[activeSheet - 1][1]) / 2,
    //     height: 0,
    //     width: 0,
    //   })
    // );
    setLayerPDFDimensions(calculateDimensions());

    dispatch(
      updateGlobalCoordLayer({
        x: 10,
        y: 0,
        height: 0,
        width: 0,
      })
    );

    const initZoom =
      window.innerHeight / 297 -
      (canvasWidth - activePageSize[0] > 150 ? 0.5 : 0.8);

    const newX =
      -globalCoordLayer.x * initZoom + canvasWidth / 2 - activePageSize[0] / 2;
    const newY =
      -globalCoordLayer.y * initZoom + canvasHeight / 2 - activePageSize[1] / 2;

    // console.log("INIT VALUES LAYER STAGE -> ", {
    //   innerWidth: window.innerWidth,
    //   innerHeight: window.innerHeight,
    //   initZoom,
    //   canvasWidth,
    //   canvasHeight,
    //   layerPDFWidth,
    //   layerPDFHeight,
    //   globalCoordLayer,
    //   newX,
    //   newY,
    //   layerWidth: sizeGlobalSheet[activeSheet - 1][0],
    //   layerHeight: sizeGlobalSheet[activeSheet - 1][1],
    //   layerRef: refLayer,
    // });

    dispatch(
      updateStageZoom({
        scale: 0.9,
        x: newX - (newX - activePageSize[0]) / 2,
        y: 25,
      })
    );
    // setLayerPDFWidth(sizeGlobalSheet[activeSheet - 1][0] * heightCalc);
    // setLayerPDFHeight(sizeGlobalSheet[activeSheet - 1][1] * heightCalc);
  }, [activePageSize, canvasWidth, canvasHeight]); // Dependencias para ejecutar al cambiar tamaño de página o lienzo

  window.addEventListener("resize", () => {
    // const valueReduce = window.innerWidth < breakpoints.tabletSValue ? 0.8 : 0.3;
    // const heightCalc = window.innerHeight / sizeGlobalSheet[activeSheet - 1][1] - valueReduce;
    // setLayerPDFWidth(sizeGlobalSheet[activeSheet - 1][0] * heightCalc);
    // setLayerPDFHeight(sizeGlobalSheet[activeSheet - 1][1] * heightCalc);
  });

  const { width: layerPDFWidth, height: layerPDFHeight } = layerPDFDimensions;

  return (
    <Layer ref={refLayer} width={layerPDFWidth} height={layerPDFHeight}>
      <Rect
        x={globalCoordLayer.x}
        y={globalCoordLayer.y}
        width={layerPDFWidth}
        height={layerPDFHeight}
        fill={"white"}
        shadowColor="#797979e6"
        shadowBlur={12}
        draggable={false}
        onMouseDown={() => {
          dispatch(updateActiveIDKonva(""));
        }}
      />
      {children}
    </Layer>
  );
};

export default LayerEditor;
