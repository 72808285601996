import React from "react";
import styled from "styled-components";
import BackgroundLamflex from "../../../assets/img/fondo_lamflex.jpg";
import SectionMax from "../../../components/section-max/section-max";
import { breakpoints } from "../../../constants/breakpoints";
import { Alert, Grid, Snackbar } from "@mui/material";
import { usePostRedimirCouponMutation } from "../../../core/store/coupon/couponAPI";
import { useForm } from "react-hook-form";
import {
  CouponWithoutCaptchaForm,
  CouponWithoutCaptchaSchema,
} from "../../../core/models/coupon-model";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getStatusAuthenticated,
  updateStatusModalLogin,
} from "../../../core/store/app-store/appSlice";
import {
  ETemporalActions,
  updateTemporalAction,
} from "../../../core/store/temporal/temporalSlice";

const WrapperBannerLamflex = styled.div`
    padding: 60px 5px;
    height: 100%;
    width: 100%;
    background-image url(${BackgroundLamflex});
    background-repeat: repeat;
    background-size: contain;
    background-position: 100% 100%;
`;

const WrapperMainBanner = styled.div`
  background: red;
  padding: 15px;
  margin: auto;
  width: 80%;
`;
const TitleBanner = styled.div`
  border: 2px solid white;
  text-align: center;
  padding: 15px;
  width: 100%;

  > h2 {
    font-weight: 900;
    font-size: 110px;
    color: white;
    margin: 0;

    ${breakpoints.tabletS} {
      font-size: 80px;
    }
    ${breakpoints.phoneL} {
      font-size: 50px;
    }
    ${breakpoints.phone} {
      font-size: 25px;
    }
  }

  > h5 {
    color: white;
    margin: 0;
    font-weight: 900;
    font-size: 40px;

    ${breakpoints.tabletS} {
      font-size: 28px;
    }
    ${breakpoints.phoneL} {
      font-size: 18px;
    }
    ${breakpoints.phone} {
      font-size: 12px;
    }
  }

  > p {
    color: white;

    ${breakpoints.tabletS} {
      font-size: 15x;
    }
    ${breakpoints.phoneL} {
      font-size: 12px;
    }
    ${breakpoints.phone} {
      font-size: 10px;
    }
  }
`;

const WrapperButton = styled.div`
  border-bottom: 10px solid #053d76;
  background: #001743;
  padding: 10px 20px;
  width: fit-content;
  margin: auto;
  margin-top: 10px;
  font-size: 20px;
  cursor: pointer;
  color: white;

  ${breakpoints.tabletS} {
    font-size: 12x;
  }
  ${breakpoints.phoneL} {
    font-size: 8px;
  }
  ${breakpoints.phone} {
    font-size: 8px;
  }
`;

const FormContainer = styled.div`
> div:nth-child(1), div:nth-child(2){
  justify-content: center;
    flex-direction: column;
    align-items: left;
    display: flex;
    padding 8px 15px;
    
    > input {
      box-shadow: 0px 4px 8px 5px rgba(198, 198, 198, 0.25);
      background-color: transparent;
      border: 1px solid white;
      border-radius: 12px;
      text-align: center;
      padding: 8px 14px;
      max-width: 280px;
      font-weight: 900;
      font-size: 32px;
      outline: none;
      color: white;
      margin: auto;
      width: 100%;
      
      ${breakpoints.tabletS} {
        font-size: 28px;
      }
      ${breakpoints.phoneL} {
        font-size: 18px;
      }
      ${breakpoints.phone} {
        font-size: 12px;
      }

      ::placeholder{
        color: white;
      }
      ::-ms-input-placeholder{
        color: white;
      }
    }
  }
  
  > div:nth-child(3){
    text-align: right;
    padding-right: 15px;
  }
`;
const ContainerEmailStyle = styled.div``;

const ErrorMessage = styled.span`
  font-size: 10px;
  color: red;
  margin-top: 6px;
`;

const SectionBannerLamFlex: React.FC = () => {
  const [redimirCoupon, resultCoupon] = usePostRedimirCouponMutation();
  const [msgErrorCoupon, setMsgErrorCoupon] = React.useState<string>("");
  const [statusSnackbar, setStatusSnackbar] = React.useState(false);
  const [valueFlex, setValueFlex] = React.useState<string>("");
  const isAuthenticated = useAppSelector(getStatusAuthenticated);
  const dispatch = useAppDispatch();

  const methods = useForm<CouponWithoutCaptchaForm>({
    resolver: yupResolver(CouponWithoutCaptchaSchema),
    defaultValues: {
      code: "",
    },
  });

  const {
    handleSubmit: submitWrapper,
    formState: { errors },
    setValue,
  } = methods;

  const handleSubmit = React.useCallback((data: any) => {
    redimirCoupon(data.code)
      .unwrap()
      .catch((error) => setMsgErrorCoupon(error.data.message));
  }, []);

  React.useEffect(() => {
    if (resultCoupon.data != null) {
      toast.success("Cupón canjeado exitosamente");
    }
  }, [resultCoupon.isSuccess]);

  React.useEffect(() => {
    if (resultCoupon.isError) {
      setStatusSnackbar(true);
    }
  }, [resultCoupon.isError]);

  React.useEffect(() => {
    if (errors?.code?.message != null) {
      toast.error(errors?.code?.message);
    }
  }, [errors?.code?.message]);

  const handleSetValueFlex = React.useCallback(
    (e: any) => {
      const value = e.target.value;
      let formattedValue = value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
      // const formattedOutput = formattedValue.replace(/(.{4})/g, "$1-");
      if (formattedValue.length > 4 && formattedValue.length < 9) {
        formattedValue = formattedValue.slice(0, 10); // Limita la longitud a 8 caracteres
        formattedValue = formattedValue.replace(/(.{4})/g, "$1-"); // Agrega el guion cada 4 caracteres
        console.log(" > 4 && < 9");
      }

      console.log("< 10", formattedValue.length);
      if (formattedValue.length < 10) {
        setValueFlex(formattedValue);
      }
      // setValueFlex(formattedOutput.slice(0, 9));
    },
    [valueFlex]
  );

  return (
    <>
      <WrapperBannerLamflex>
        <SectionMax>
          <WrapperMainBanner>
            <TitleBanner>
              <h2>LAMFLEX</h2>
              <Grid item xs={12}>
                <FormContainer>
                  <ContainerEmailStyle>
                    <input
                      placeholder="a b c - x x x"
                      type="text"
                      value={valueFlex}
                      onChange={handleSetValueFlex}
                      required
                    />
                  </ContainerEmailStyle>
                </FormContainer>
              </Grid>
              <WrapperButton
                onClick={() => {
                  if (isAuthenticated) {
                    submitWrapper(handleSubmit)();
                  } else {
                    dispatch(
                      updateTemporalAction(ETemporalActions.OPEN_COUPON)
                    );
                    dispatch(updateStatusModalLogin(true));
                  }
                }}
              >
                CANJEA AQUI
              </WrapperButton>
              <p>
                LamFlex tarjeta prepago para láminas digitales, acceso
                instantáneo y flexible
              </p>
            </TitleBanner>
          </WrapperMainBanner>
        </SectionMax>
      </WrapperBannerLamflex>
      <Snackbar
        open={statusSnackbar}
        autoHideDuration={6000}
        onClose={() => setStatusSnackbar(false)}
      >
        <Alert
          severity="error"
          sx={{ width: "100%" }}
          onClose={() => setStatusSnackbar(false)}
          elevation={6}
        >
          {msgErrorCoupon
            ? msgErrorCoupon
            : "Hubo un error con el código de cupón"}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SectionBannerLamFlex;
