import React from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  IDataSave,
  IObjectComponents,
  IPage,
  addItemKonva,
  addPage,
  loadPages,
  setActivePage,
  updateActiveIDKonva,
} from "../../core/store/konva-editor/konva-editorSlice";
import { ComponentKonvaItem } from "../../features/editor-konva/editor-konva";
import { KonvaTypeItem } from "../../features/editor-konva/components/global-item-konva/global-item-konva";

const useLoadSavedJob = () => {
  const dispatch = useAppDispatch();
  const handleLoadJobSaved = (data: string) => {
    console.log("Datos recibidos:", data);

    try {
      const validData = JSON.parse(data);
      const dataJob = JSON.parse(validData);
      console.log("Datos parseados:", dataJob);

      if (Array.isArray(dataJob) && dataJob.length > 0) {
        if (dataJob.every((item) => "type" in item)) {
          // Si todos los elementos tienen la propiedad 'type', se asume que son ComponentKonvaItem
          const components = dataJob as ComponentKonvaItem[];

          // Crear un nuevo IPage con estos componentes
          const newPage: IPage = {
            id: "page_" + Date.now().toString(), // Generar un ID único
            components: components,
            size: [210, 297], // Tamaño predefinido, puede ajustarse si es necesario
          };

          dispatch(loadPages([newPage]));
          console.log(
            "Se ha creado una nueva página con los componentes:",
            newPage
          );
        } else {
          // Si es un array de IPage, se procesa normalmente
          const pagesToLoad: IPage[] = dataJob as IPage[];

          dispatch(loadPages(pagesToLoad));
          console.log("Se han cargado las páginas:", pagesToLoad);
        }
      } else {
        console.warn("El JSON no contiene un array válido.");
      }
    } catch (error) {
      console.error("Error al analizar los datos:", error);
      console.warn("Formato de datos no válido.");
    }
  };

  return {
    handleLoadJobSaved,
  };
};

export default useLoadSavedJob;
