import styled from "styled-components";
import { ArrowIosUpwardOutline } from "@styled-icons/evaicons-outline/ArrowIosUpwardOutline";
import { Add } from "@styled-icons/ionicons-outline/Add";

// Contenedor general para el BottomNavBar y el Tab
export const BottomNavBarWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  z-index: 3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

// Contenedor expandible del BottomNavBar
export const BottomNavBarExpanded = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: ${(props) => (props.isOpen ? "140px" : "50px")};
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: height 0.3s ease;

  position: relative;
  z-index: 0;
`;
// Pestanita para el BottomNavBar
export const Tab = styled.div<{ isOpen: boolean }>`
  width: 120px; // Ancho de la parte superior
  height: 30px; // Altura del tab
  position: absolute;
  bottom: ${(props) =>
    props.isOpen
      ? "88%"
      : "64%"}; // Cambia la posición en función de si está abierto o cerrado
  left: calc(50% - 60px); // Centra el tab
  background-color: #ffffff; // Color del tab
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0045ac;
  border-radius: 30px; // Esquinas redondeadas para el tab
  transition: bottom 0.3s ease; // Transición suave al cambiar la posición
`;

// Contenido del BottomNavBar que se muestra cuando está expandido
export const Content = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  width: 100%;
  padding: 0;
  color: #000;
  text-align: center;
  flex-wrap: nowrap;
  gap: 20px;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 30px;
`;

// Cuadro individual en el contenido
export const Box = styled.div<{ isSelected: boolean }>`
  width: 140px;
  height: 80px;
  background-color: transparent;
  border: 2px solid ${(props) => (props.isSelected ? "#FC4A41" : "#D8D8D8")}; // Borde rojo si está seleccionado
  border-radius: 15px;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  display: inline-block;
`;

export const BoxAdd = styled.div`
  width: 140px;
  height: 80px;
  background-color: #eff0f2;
  border-radius: 15px;
  padding: 20px;
  box-sizing: border-box;
  border: 2px solid #d8d8d8;
  align-items: center;
  justify-content: center;
  display: flex;
  &:hover {
    background: #d8d8d8;
  }
`;
export const AddIcon = styled(Add)`
  width: 100%;
  max-width: 30px;
  color: black;
`;
// Número dentro del círculo en la esquina superior izquierda
export const NumberCircle = styled.div<{ isSelected: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #0045ac;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -15px;
  left: -15px;
  font-size: 14px;
  font-weight: bold;
  border: 2px solid ${(props) => (props.isSelected ? "#FC4A41" : "#D8D8D8")}; // Borde rojo si está seleccionado
`;

export const ArrowIcon = styled(ArrowIosUpwardOutline)`
  width: 16px;
  height: 16px;
  font-weight: bold;
  color: #0045ac;
`;
