import React from "react";
import { Accordion } from "react-accordion-ts";
// import { AccordionItem } from "../../reason-section";
import "react-accordion-ts/src/panel.css";
import styled from "styled-components";
import { listFAQ } from "./list-faq";
import { Plus } from "@styled-icons/bootstrap/Plus";

const ContainerAccordion = styled.div`
  max-width: 800px;
  width: 100%;
  margin: auto;

  > .accordion > .panel > .panel__head {
    width: 100%;
    display: flex;
    justify-content: left;
    text-align: left;
    outline: none;
  }
`;

export const ContainerItem = styled.div`
  padding: 10px 0 20px;
  width: 100%;

  > h3:nth-child(2) {
    color: #606060;
  }
`;

export const ContainerCustomText = styled.div`
  > p {
    min-height: 20px;
    max-height: 150px;
    font-size: 12px;
    padding: 10px;
    outline: none;
    width: 100%;
  }
  > ul li p {
    margin: 0;
    font-size: 12px;
  }
`;
export const ContainerTitleAccordion = styled.div`
  justify-content: space-between;
  padding: 6px 10px;
  display: flex;
  width: 100%;

  > div h4 {
    margin: 10px 0;
  }
  > div:nth-child(2) {
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > div svg {
    width: 18px;
    font-weight: 600;
  }
`;

const SectionFaq: React.FC = () => {
  const items = (listFAQ || []).map(
    ({ open, question, response, listSteps, paragraph01, paragraph02 }) => ({
      open,
      title: (
        <ContainerTitleAccordion>
          <div>
            <h4>{question}</h4>
          </div>
          <div>
            <Plus />
          </div>
        </ContainerTitleAccordion>
      ),
      content: (
        <ContainerItem>
          <ContainerCustomText>
            <p>{response}</p>
            {listSteps.length > 0 && (
              <>
                <ul>
                  {listSteps.map((step) => (
                    <li key={Date.now()}>
                      <p>{step}</p>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {paragraph01 != "" && <p>{paragraph01}</p>}
            {paragraph02 != "" && <p>{paragraph02}</p>}
          </ContainerCustomText>
        </ContainerItem>
      ),
    })
  );

  return (
    <ContainerAccordion>
      <Accordion items={items} duration={300} multiple={false} />
    </ContainerAccordion>
  );
};

export default SectionFaq;
