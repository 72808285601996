import React from "react";
import HeaderSearch from "../../components/header-search/header-search";
import useSearchSheet from "../../utils/hooks/use-search-sheet";
import styled from "styled-components";
import SectionMax from "../../components/section-max/section-max";
import SectionFaq from "../../components/section-faq/section-faq";

const WrapperFAQ = styled.div`
  padding: 20px;
  width: 100%;
`;

const FaqPage: React.FC = () => {
  const { handleSetData, handleKeyUp } = useSearchSheet();
  return (
    <>
      <HeaderSearch
        title="Preguntas Frecuentes"
        handleSetData={handleSetData}
        handleKeyUp={handleKeyUp}
      />
      <WrapperFAQ>
        <SectionMax>
          <SectionFaq />
        </SectionMax>
      </WrapperFAQ>
    </>
  );
};

export default FaqPage;
