import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ComponentKonvaItem } from "../../../features/editor-konva/editor-konva";
import { breakpoints } from "../../../constants/breakpoints";
import { IInfoJobSave } from "../sheets/konva/konva-type";

export interface IObjectComponents {
  components: ComponentKonvaItem[];
}

export interface IDataSave {
  id: number;
  uuid: string;
  name: string;
  preview: string;
  data: IPage[];
  sheets: string[];
  userId: number;
}

export interface GlobalCoordText {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface GlobalCoordLayer {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface ConfigStageZoom {
  scale: number;
  x: number;
  y: number;
}

export interface ITextAreaCurrentStyle {
  currentTextFontSize: number;
  currentTextFontFamily: string;
  currentTextColor: string;
  currentTextAlign: string;
}

export interface IPage {
  id: string;
  components: ComponentKonvaItem[];
  size: number[]; // Ancho y alto de la hoja
}

export interface EditorState {
  pages: IPage[];
  activePageID: string; // ID de la página activa
  sizeGlobalSheet: number[][];
  activeGlobalSheet: number;
  canvasWidth: number;
  canvasHeight: number;
  activeMenuOption: number;
  listComponentsKonva: ComponentKonvaItem[];
  activeKonvaComponentID: string; // ID del componente activo de KONVA
  groupCoordGlobaltext: GlobalCoordText; //Coordenadas para textarea de editText
  groupCoordGlobalLayer: GlobalCoordLayer; //Coordenadas para textarea de editText
  isActiveModalCutImage: boolean; // Solo para imagenes
  activeIdImageEditing: string; // Solo para imagenes
  urlImageActiveEditing: string; // Solo para imagenes
  showModalEditingImage: boolean; // Solo para imagenes
  stageZoom: ConfigStageZoom; // Solo para Stage
  textAreaStyles: ITextAreaCurrentStyle; // Solo para texto
  currentEditingText: ComponentKonvaItem;
  isActivePanelEditor: boolean; // Flag para mostrar o desaparecer menus flotantes
  isApplicationPaused: boolean;
  statusCursorCanva: number;
  statusBSSettings: boolean; // Menu solo para movil
  statusBSImage: boolean; // Menu solo para movil
  statusBSText: boolean; // Menu solo para movil
  statusBSShape: boolean; // Menu solo para movil
  dataSave: IDataSave;
  isLoadingDataSave: boolean; // Indicador de carga de trabajos guardados
  infoJobSaved: IInfoJobSave; // Indicador de carga de trabajos guardados
}

const initialState: EditorState = {
  pages: [
    {
      id: "1",
      components: [],
      size: [210, 297],
    },
  ],
  activePageID: "1",

  sizeGlobalSheet: [
    [210, 297],
    [215, 330],
    [297, 420],
    [594, 841],
  ],
  canvasWidth: window.innerWidth - 10,
  canvasHeight:
    window.innerWidth < breakpoints.tabletSValue
      ? window.innerHeight - 110
      : window.innerHeight - 50,
  activeGlobalSheet: 1,
  activeMenuOption: 0,
  listComponentsKonva: [],
  activeKonvaComponentID: "",
  groupCoordGlobaltext: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  groupCoordGlobalLayer: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  isActiveModalCutImage: false,
  activeIdImageEditing: "",
  urlImageActiveEditing: "",
  showModalEditingImage: false,
  stageZoom: {
    scale: 1,
    x: 0,
    y: 0,
  },
  textAreaStyles: {} as ITextAreaCurrentStyle,
  currentEditingText: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    text: "",
    customFontSize: 0,
    customFill: "",
    customAlign: "",
    customFamily: "",
  } as ComponentKonvaItem,
  isActivePanelEditor: true,
  isApplicationPaused: false,
  statusCursorCanva: 1,
  statusBSSettings: false,
  statusBSImage: false,
  statusBSText: false,
  statusBSShape: false,
  dataSave: {
    id: 0,
    uuid: "",
    name: "",
    preview: "",
    data: [],
    sheets: [],
    userId: 0,
  },
  isLoadingDataSave: false,
  infoJobSaved: {
    id: 0,
    uuid: "",
    data: "",
    isActiveJobLoad: false,
  },
};

export const konvaEditorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    addPage: (state) => {
      const newPage = {
        id: `${state.pages.length + 1}`,
        components: [],
        size: [210, 297],
      };
      state.pages.push(newPage);
      state.activePageID = newPage.id; // Activar la nueva página
    },
    updatePage: (state, action: PayloadAction<ComponentKonvaItem>) => {
      const page = state.pages.find((page) => page.id === state.activePageID);
      if (page) {
        const componentIndex = page.components.findIndex(
          (component) => component.id === action.payload.id
        );
        if (componentIndex >= 0) {
          page.components[componentIndex] = action.payload;
        }
      }
    },
    loadPages: (state, action: PayloadAction<IPage[]>) => {
      state.pages.length = 0; // Elimina todas las páginas existentes
      state.pages.push(...action.payload); // Agrega las nuevas páginas
      state.activePageID = action.payload[0]?.id || ""; 
    },
    
    setActivePage: (state, action: PayloadAction<string>) => {
      state.activePageID = action.payload;
    },

    deletePage: (state, action: PayloadAction<string>) => {
      // No permitir la eliminación si solo hay una página
      if (state.pages.length <= 1) {
        return;
      }

      const pageIndex = state.pages.findIndex(
        (page) => page.id === action.payload
      );

      if (pageIndex !== -1) {
        // Eliminar la página
        state.pages.splice(pageIndex, 1);

        // Reasignar IDs para mantener el orden
        state.pages.forEach((page, index) => {
          page.id = `${index + 1}`;
        });

        // Si hay páginas restantes, establecer la página activa como la de la izquierda
        if (state.pages.length > 0) {
          // Si se eliminó una página que estaba a la izquierda, selecciona la que ahora está en esa posición
          if (pageIndex > 0) {
            state.activePageID = state.pages[pageIndex - 1].id; // Página a la izquierda
          } else {
            state.activePageID = state.pages[0].id; // Primera página
          }
        } else {
          // Si no hay páginas restantes, limpiar el activePageID
          state.activePageID = "";
        }
      }
    },

    duplicatePage: (state, action: PayloadAction<string>) => {
      const pageToDuplicate = state.pages.find(
        (page) => page.id === action.payload
      );
      if (pageToDuplicate) {
        const duplicatedPage = {
          ...pageToDuplicate,
          id: `${state.pages.length + 1}`, // Cambiar el ID para que sea único
          components: pageToDuplicate.components.map((component) => ({
            ...component,
            id: `${state.pages.length + 1}-${component.id}`, // Asegúrate de que cada componente tenga un ID único
          })),
        };
        state.pages.push(duplicatedPage);
        state.activePageID = duplicatedPage.id; // Activar la nueva página duplicada
      }
    },

    updateActiveIDKonva: (state, action: PayloadAction<string>) => {
      state.activeKonvaComponentID = action.payload;
    },
    resetDataKonva: (state) => {
      state.pages = []; // Elimina todas las páginas
      state.activePageID = "";
      const newPage = {
        id: `${state.pages.length + 1}`,
        components: [],
        size: [210, 297],
      };
      state.pages.push(newPage);
      state.activePageID = newPage.id;
    },

    updateComponentKonva: (
      state,
      action: PayloadAction<ComponentKonvaItem>
    ) => {
      const activePage = state.pages.find(
        (page) => page.id === state.activePageID
      );
      if (activePage) {
        const updatingItem = activePage.components.find(
          (component) => component.id === action.payload.id
        );
        if (updatingItem) {
          updatingItem.x = action.payload.x;
          updatingItem.y = action.payload.y;
          updatingItem.width = action.payload.width;
          updatingItem.height = action.payload.height;
          updatingItem.fill = action.payload.fill;
        }
      }
    },
    addItemKonva: (state, action: PayloadAction<ComponentKonvaItem>) => {
      const activePage = state.pages.find(
        (page) => page.id === state.activePageID
      );
      if (activePage) {
        activePage.components.push(action.payload);
      }
    },
    // Configuracion comun en todos los objetos
    deleteObjectKonva: (state) => {
      const activePage = state.pages.find(
        (page) => page.id === state.activePageID
      );
      if (activePage) {
        activePage.components = activePage.components.filter(
          (component) => component.id !== state.activeKonvaComponentID
        );
      }
      state.activeKonvaComponentID = ""; // Reiniciar el ID activo
    },
    unselectObjectKonva: (state) => {
      state.activeKonvaComponentID = "";
    },
    // Configuracion para canvas
    updateActiveGlobalSheet: (state, action: PayloadAction<number>) => {
      state.activeGlobalSheet = action.payload;
    },
    updateCanvasWidth: (state, action: PayloadAction<number>) => {
      state.canvasWidth = action.payload;
    },
    updateCanvasHeight: (state, action: PayloadAction<number>) => {
      state.canvasHeight = action.payload;
    },
    changeActivePanelEditor: (state, action: PayloadAction<boolean>) => {
      state.isActivePanelEditor = action.payload;
    },
    changeStatusApplication: (state, action: PayloadAction<boolean>) => {
      state.isApplicationPaused = action.payload;
    },
    changeStatusCursorCanva: (state, action: PayloadAction<number>) => {
      state.statusCursorCanva = action.payload;
    },
    // Solo para layers
    updateFullUpActiveKonva: (state) => {
      const page = state.pages.find((page) => page.id === state.activePageID); // Busca la página activa

      if (page) {
        const componentIndex = page.components.findIndex(
          (component) => component.id === state.activeKonvaComponentID
        ); // Encuentra el índice del componente activo en la página

        if (componentIndex >= 0) {
          const componentToMove = page.components[componentIndex]; // Obtiene el componente activo
          // Elimina el componente activo de la lista
          page.components = [
            ...page.components.slice(0, componentIndex), // Parte anterior de la lista
            ...page.components.slice(componentIndex + 1), // Parte posterior de la lista
            componentToMove, // Añade el componente al final
          ];

          state.activeKonvaComponentID = componentToMove.id; // Actualiza el ID del componente activo
        }
      }
    },
    updateUpActiveKonva: (state) => {
      const page = state.pages.find((page) => page.id === state.activePageID); // Busca la página activa

      if (page) {
        const currentIndexComponent = page.components.findIndex(
          (component) => component.id === state.activeKonvaComponentID
        ); // Encuentra el índice del componente activo en la página

        if (currentIndexComponent >= 0) {
          const currentKonvaComponent = page.components[currentIndexComponent]; // Obtiene el componente activo
          // Elimina el componente activo de la lista
          const listObjects = [
            ...page.components.slice(0, currentIndexComponent), // Parte anterior de la lista
            ...page.components.slice(currentIndexComponent + 1), // Parte posterior de la lista
          ];
          // Inserta el componente activo en la posición siguiente
          listObjects.splice(
            currentIndexComponent + 1,
            0,
            currentKonvaComponent
          );
          page.components = listObjects; // Actualiza los componentes de la página
        }
      }
    },
    updateDownActiveKonva: (state) => {
      const page = state.pages.find((page) => page.id === state.activePageID); // Busca la página activa

      if (page) {
        const currentIndexComponent = page.components.findIndex(
          (component) => component.id === state.activeKonvaComponentID
        ); // Encuentra el índice del componente activo en la página

        if (currentIndexComponent >= 0) {
          const currentKonvaComponent = page.components[currentIndexComponent]; // Obtiene el componente activo
          // Elimina el componente activo de la lista
          const listObjects = [
            ...page.components.slice(0, currentIndexComponent), // Parte anterior de la lista
            ...page.components.slice(currentIndexComponent + 1), // Parte posterior de la lista
          ];
          // Inserta el componente activo en la posición anterior
          const insertIndex =
            currentIndexComponent > 0 ? currentIndexComponent - 1 : 0;
          listObjects.splice(insertIndex, 0, currentKonvaComponent);
          page.components = listObjects; // Actualiza los componentes de la página
        }
      }
    },
    updateFullDownActiveKonva: (state) => {
      const page = state.pages.find((page) => page.id === state.activePageID); // Busca la página activa

      if (page) {
        const currentIndexComponent = page.components.findIndex(
          (component) => component.id === state.activeKonvaComponentID
        ); // Encuentra el índice del componente activo en la página

        if (currentIndexComponent >= 0) {
          const currentKonvaComponent = page.components[currentIndexComponent]; // Obtiene el componente activo
          // Elimina el componente activo de la lista
          const listObjects = [
            currentKonvaComponent, // Coloca el componente activo al inicio
            ...page.components.filter(
              (_, index) => index !== currentIndexComponent
            ), // Parte posterior sin el componente activo
          ];
          page.components = listObjects; // Actualiza los componentes de la página
          state.activeKonvaComponentID = currentKonvaComponent.id; // Actualiza el ID del componente activo
        }
      }
    },

    // Estado de menu activo
    updateActiveMenuOption: (state, action: PayloadAction<number>) => {
      state.activeMenuOption = action.payload;
    },
    // Solo para figuras
    // Configuración común en todos los objetos
    updateColorFillFigure: (state, action: PayloadAction<string>) => {
      const activePage = state.pages.find(
        (page) => page.id === state.activePageID
      );

      if (activePage) {
        const updatingItem = activePage.components.find(
          (component) => component.id === state.activeKonvaComponentID
        );

        if (updatingItem) {
          updatingItem.color = action.payload; // Actualizar el color con el valor de la acción
        }
      }
    },
    updateStrokeFillFigure: (state, action: PayloadAction<string>) => {
      const page = state.pages.find((page) => page.id === state.activePageID); // Busca la página activa

      if (page) {
        const updatingItem = page.components.find(
          (component) => component.id === state.activeKonvaComponentID
        ); // Encuentra el componente activo en la página

        if (updatingItem) {
          updatingItem.stroke = action.payload; // Actualiza el color de trazo
        }
      }
    },

    updateStrokeSizeFigure: (state, action: PayloadAction<number>) => {
      const page = state.pages.find((page) => page.id === state.activePageID); // Busca la página activa

      if (page) {
        const updatingItem = page.components.find(
          (component) => component.id === state.activeKonvaComponentID
        ); // Encuentra el componente activo en la página

        if (updatingItem) {
          updatingItem.sizeStroke = action.payload; // Actualiza el tamaño del trazo
        }
      }
    },

    updateGlobalCoordLayer: (
      state,
      action: PayloadAction<GlobalCoordLayer>
    ) => {
      state.groupCoordGlobalLayer = action.payload;
    },
    // Solo para Texto
    updateGlobalCoordText: (state) => {
      const page = state.pages.find((page) => page.id === state.activePageID); // Busca la página activa

      if (page) {
        const updatingItem = page.components.find(
          (component) => component.id === state.activeKonvaComponentID
        ); // Encuentra el componente activo en la página

        if (updatingItem) {
          // Actualiza las coordenadas globales del texto
          state.groupCoordGlobaltext.x = updatingItem.x;
          state.groupCoordGlobaltext.y = updatingItem.y;
          state.groupCoordGlobaltext.width = updatingItem.width;
          state.groupCoordGlobaltext.height = updatingItem.height;

          // Actualiza el componente actual que se está editando
          state.currentEditingText = updatingItem;
        }
      }
    },

    updateOldTextEditing: (state) => {
      state.groupCoordGlobaltext.x = 0;
      state.groupCoordGlobaltext.y = 0;
      state.groupCoordGlobaltext.width = 0;
      state.groupCoordGlobaltext.height = 0;
    },
    // Solo Rich texto
    updateColorRichText: (state, action: PayloadAction<string>) => {
      const page = state.pages.find((page) => page.id === state.activePageID); // Busca la página activa

      if (page) {
        const updatingItem = page.components.find(
          (component) => component.id === state.activeKonvaComponentID
        ); // Encuentra el componente activo en la página

        if (updatingItem) {
          updatingItem.customFill = action.payload; // Actualiza el color
        }
      }
    },

    updateSizeRichText: (state, action: PayloadAction<number>) => {
      const page = state.pages.find((page) => page.id === state.activePageID); // Busca la página activa

      if (page) {
        const updatingItem = page.components.find(
          (component) => component.id === state.activeKonvaComponentID
        ); // Encuentra el componente activo en la página

        if (updatingItem) {
          updatingItem.customFontSize = action.payload; // Actualiza el tamaño
        }
      }
    },

    updateFamilyRichText: (state, action: PayloadAction<string>) => {
      const page = state.pages.find((page) => page.id === state.activePageID); // Busca la página activa

      if (page) {
        const updatingItem = page.components.find(
          (component) => component.id === state.activeKonvaComponentID
        ); // Encuentra el componente activo en la página

        if (updatingItem) {
          updatingItem.customFamily = action.payload; // Actualiza la familia de fuente
        }
      }
    },

    updateAlignRichText: (state, action: PayloadAction<string>) => {
      const page = state.pages.find((page) => page.id === state.activePageID); // Busca la página activa

      if (page) {
        const updatingItem = page.components.find(
          (component) => component.id === state.activeKonvaComponentID
        ); // Encuentra el componente activo en la página

        if (updatingItem) {
          updatingItem.customAlign = action.payload; // Actualiza la alineación
        }
      }
    },

    updateActiveTextProperties: (state) => {
      const page = state.pages.find((page) => page.id === state.activePageID); // Busca la página activa

      if (page) {
        const updatingItem = page.components.find(
          (component) => component.id === state.activeKonvaComponentID
        ); // Encuentra el componente activo en la página

        if (updatingItem) {
          const newTextStyles = {
            currentTextAlign: updatingItem.customAlign,
            currentTextColor: updatingItem.customFill,
            currentTextFontSize: updatingItem.customFontSize,
            currentTextFontFamily: updatingItem.customFamily,
          } as ITextAreaCurrentStyle;

          state.textAreaStyles = newTextStyles;
          const txtarea: HTMLTextAreaElement | any =
            document.getElementById("global-text-editor");
          txtarea!.innerText = updatingItem.text!;
          txtarea!.value = updatingItem.text!;
          updatingItem.text = ""; // Limpia el texto en el componente
          txtarea?.focus(); // Enfoca el área de texto
        }
      }
    },

    // Solo para Imagenes
    updateCutImageText: (state) => {
      const page = state.pages.find((page) => page.id === state.activePageID); // Busca la página activa

      if (page) {
        const updatingItem = page.components.find(
          (component) => component.id === state.activeKonvaComponentID
        ); // Encuentra el componente activo en la página

        if (updatingItem) {
          state.activeIdImageEditing = state.activeKonvaComponentID; // Establece el ID del componente activo
          state.urlImageActiveEditing = updatingItem.image!; // Actualiza la URL de la imagen
        }
      }
    },

    updateVisilibityImageEdit: (state) => {
      state.showModalEditingImage = !state.showModalEditingImage;
    },
    updateNewImageActiveEdit: (state, action: PayloadAction<string>) => {
      const page = state.pages.find((page) => page.id === state.activePageID); // Busca la página activa

      if (page) {
        const updatingItem = page.components.find(
          (component) => component.id === state.activeIdImageEditing
        ); // Encuentra el componente activo en la página

        if (updatingItem) {
          updatingItem.image = action.payload; // Actualiza la imagen del componente
        }
      }
    },

    updateStageZoom: (state, action: PayloadAction<ConfigStageZoom>) => {
      state.stageZoom = action.payload;
    },
    updateDataSave: (state, action: PayloadAction<IDataSave>) => {
      state.dataSave = action.payload;
    },
    updateIsLoadingDataSave: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDataSave = action.payload;
    },
    updateInfoJobSaved: (state, action: PayloadAction<IInfoJobSave>) => {
      state.infoJobSaved = action.payload;
    },
  },
});

export const {
  addPage,
  updatePage,
  deletePage,
  loadPages,
  setActivePage,
  duplicatePage,
  addItemKonva,
  resetDataKonva,
  updateActiveIDKonva,
  updateComponentKonva,
  updateActiveGlobalSheet,
  updateCanvasWidth,
  updateCanvasHeight,
  updateActiveMenuOption,
  changeStatusCursorCanva, // Solo para Canvas
  changeActivePanelEditor, // Solo para Canvas
  updateFullUpActiveKonva, // Solo para Canvas
  updateUpActiveKonva, // Solo para Canvas
  updateDownActiveKonva, // Solo para Canvas
  updateFullDownActiveKonva, // Solo para Canvas
  deleteObjectKonva, // Todos los objetos
  unselectObjectKonva, // Todos los objetos
  updateColorFillFigure, // Solo Figuras
  updateStrokeFillFigure, // Solo Figuras
  updateStrokeSizeFigure, // Solo Figuras
  updateGlobalCoordText, // Solo Texto
  updateColorRichText, // Solo texto
  updateSizeRichText, // Solo texto
  updateFamilyRichText, // Solo texto
  updateAlignRichText, // Solo texto
  updateActiveTextProperties, // Solo texto
  updateOldTextEditing, // Solo texto
  updateCutImageText, // Solo Imagenes
  updateVisilibityImageEdit, // Solo Imagenes
  updateNewImageActiveEdit, // Solo Imagenes
  updateStageZoom, // Solo Stage
  changeStatusApplication,
  updateDataSave, // Solo para los trabajos guardados
  updateIsLoadingDataSave,
  updateInfoJobSaved,
  updateGlobalCoordLayer,
} = konvaEditorSlice.actions;

export const getActivePage = (state: RootState) =>
  state.konvaEditor.pages.find(
    (page) => page.id === state.konvaEditor.activePageID
  );

export const getActiveComponents = (state: RootState) => {
  const page = state.konvaEditor.pages.find(
    (page) => page.id === state.konvaEditor.activePageID
  );
  return page ? page.components : [];
};
export const getPages = (state: RootState) => state.konvaEditor.pages;
export const getActivePageID = (state: RootState) =>
  state.konvaEditor.activePageID;

export const getActivePageSize = (state: RootState): number[] => {
  const activePage = state.konvaEditor.pages.find(
    (page) => page.id === state.konvaEditor.activePageID
  );
  return activePage ? activePage.size : [210, 297]; // Devuelve [210, 297] si no se encuentra la página activa
};
export const getSizeGlobalSheet = (state: RootState) =>
  state.konvaEditor.sizeGlobalSheet;

export const getActiveGlobalSheet = (state: RootState) =>
  state.konvaEditor.activeGlobalSheet;
export const getCanvasWidth = (state: RootState) =>
  state.konvaEditor.canvasWidth;
export const getCanvasHeight = (state: RootState) =>
  state.konvaEditor.canvasHeight;
export const getActiveMenuOption = (state: RootState) =>
  state.konvaEditor.activeMenuOption;
export const getListComponentsKonva = (state: RootState) =>
  state.konvaEditor.listComponentsKonva;
export const getActiveComponentKonvaID = (state: RootState) =>
  state.konvaEditor.activeKonvaComponentID;
export const getGlobalCoord = (state: RootState) =>
  state.konvaEditor.groupCoordGlobaltext;
export const getGlobalCoordLayer = (state: RootState) =>
  state.konvaEditor.groupCoordGlobalLayer;
export const getUrlActiveImageEdit = (state: RootState) =>
  state.konvaEditor.urlImageActiveEditing;
export const getVisibilityModalImageEdit = (state: RootState) =>
  state.konvaEditor.showModalEditingImage;
export const getConfigStageZoom = (state: RootState) =>
  state.konvaEditor.stageZoom;
export const getCurrentStylesTextArea = (state: RootState) =>
  state.konvaEditor.textAreaStyles;
export const getOldPropertiesTextEditing = (state: RootState) =>
  state.konvaEditor.currentEditingText;
export const getStatusPanelEditor = (state: RootState) =>
  state.konvaEditor.isActivePanelEditor;
export const getStatusApplication = (state: RootState) =>
  state.konvaEditor.isApplicationPaused;
export const getStatusCursorCanva = (state: RootState) =>
  state.konvaEditor.statusCursorCanva;

export const getCurrentPropertiesKonva = (state: RootState) => {
  const activePage = state.konvaEditor.pages.find(
    (page) => page.id === state.konvaEditor.activePageID
  );

  const defaultProperties = {
    id: "",
    uuid: "",
    type: "default",
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    fill: "white",
    text: "",
    fontSize: 12,
    align: "left",
    image: "",
    component: null,
    color: "black",
    stroke: "black",
    customFill: "",
    customFontSize: 12,
    customAlign: "left",
    customFamily: "Arial",
    sizeStroke: 1,
  };

  if (activePage) {
    const component = activePage.components.find(
      (item) => item.id === state.konvaEditor.activeKonvaComponentID
    );
    return component || defaultProperties;
  }

  return defaultProperties;
};

export const getDataSave = (state: RootState) => state.konvaEditor.dataSave;
export const getIsLoadingInfoJobs = (state: RootState) =>
  state.konvaEditor.isLoadingDataSave;
export const getInfoJobsSaved = (state: RootState) =>
  state.konvaEditor.infoJobSaved;

export default konvaEditorSlice.reducer;
