import React from "react";
import styled from "styled-components";
import { Grid, Typography, MenuItem } from "@mui/material";
import SearchLamina from "../../../../components/search-lamina/search-lamina";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CardSaved from "../../../../components/card-saved/card-saved";
import { useGetAllPersonalJobsPaginateMutation } from "../../../../core/store/konva-editor/konvaAPI";
import { IPersonalJobsResponse } from "../../../../core/store/sheets/konva/konva-type";
import { ButtonMoreResults } from "../../../editor-konva/components/menu-options/sub-menu-imagen/sub-menu-image-style";

const WrapperFavourites = styled.div`
  position: relative;
  padding: 20px;
`;

const ListFavourites = styled.div`
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 2rem;

  @media (min-width: 100px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 520px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 728px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const SavedSection: React.FC = () => {
  const [favouritesNumber, setFavouritesNumber] = React.useState("");
  const [listJobs, setListJobs] = React.useState<IPersonalJobsResponse>();
  const [listTemporalJobs, setListTemporalJobs] =
    React.useState<IPersonalJobsResponse>();
  const [wordSearch, setWordSearch] = React.useState<string>();

  const handleChange = (event: SelectChangeEvent) => {
    setFavouritesNumber(event.target.value);
  };

  const [getAllPersonalJobs, resultJobs] =
    useGetAllPersonalJobsPaginateMutation();

  React.useEffect(() => {
    getAllPersonalJobs("");
  }, []);

  React.useEffect(() => {
    if (resultJobs != null && resultJobs != undefined) {
      if (resultJobs.data != null) {
        setListTemporalJobs(resultJobs.data);
        setListJobs(resultJobs.data);
      }
    }
  }, [resultJobs]);

  const handleSetDataSearch = (data: any) => {
    setWordSearch(data);
  };

  React.useEffect(() => {
    if (wordSearch != "" && wordSearch != undefined) {
      const newJobs = listTemporalJobs?.data?.filter((job) => {
        return job.name.toLowerCase().includes(wordSearch.toLowerCase());
      });
      setListJobs((jobs: any) => ({
        ...jobs,
        data: newJobs,
        total: newJobs?.length,
      }));
    } else {
      setListJobs(listTemporalJobs);
    }
  }, [wordSearch, listTemporalJobs]);

  return (
    <WrapperFavourites>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body2" component="p">
            Se han encontrado
            <Typography component="span" color="red" fontWeight={600}>
              {` ${resultJobs.data?.total || 0} `}
            </Typography>
            trabajo{(resultJobs.data?.total || 0) != 1 && "s"} guardado
            {(resultJobs.data?.total || 0) != 1 && "s"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={7} marginTop={2} alignSelf={"center"}>
          {/* <Typography
            variant="body1"
            component="span"
            fontWeight={400}
            paddingRight={2}
          >
            Mostrar
          </Typography>
          <Select
            value={favouritesNumber}
            onChange={handleChange}
            sx={{
              width: "fit-content",
              padding: "5px",
              borderRadius: "20px",
              maxHeight: "45px",
            }}
          >
            <MenuItem value={10} selected>
              10
            </MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select> */}
        </Grid>
        <Grid item xs={12} md={5} marginTop={2} alignSelf={"center"}>
          <SearchLamina
            placeHolder="Buscar"
            customStyle={`
              border-radius: 30px;
              background: #FFF;
              box-shadow: 0px 6px 20px 10px rgba(156, 156, 156, 0.25);
              backdrop-filter: blur(12.5px);
              `}
            handleSetData={handleSetDataSearch}
            isSearchOtherFunc={true}
          />
        </Grid>
        <Grid item xs={12} marginTop={2}>
          <ListFavourites>
            {listJobs?.data?.map((job) => {
              const dataAsString = JSON.stringify(job.data);

              return (
                <CardSaved
                  key={job.uuid}
                  id={job.id}
                  uuid={job.uuid}
                  image={job.preview}
                  name={job.name}
                  dataSaved={dataAsString}
                  onRefreshListData={() => getAllPersonalJobs("")}
                />
              );
            })}
          </ListFavourites>
        </Grid>
        {/* {!resultJobs.isLoading &&
          !!resultJobs &&
          !!resultJobs.data &&
          resultJobs.data?.nextPageUrl != null && (
            <>
              <ButtonMoreResults onClick={handleMoreResults}>
                Más resultados
              </ButtonMoreResults>
            </>
          )} */}
      </Grid>
    </WrapperFavourites>
  );
};

export default SavedSection;
