import React from "react";
import ReactDOM from "react-dom";
import {
  LoaderStyles,
  ModalContent,
  ModalOverlay,
} from "./modal-qr-loading.styles";

interface IOwnProps {
  isOpen: boolean;
}

const ModalQRLoading: React.FC<IOwnProps> = ({ isOpen }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <ModalOverlay>
      <ModalContent>
        <div>
          <p>¡Canjeando el cupon!</p>
        </div>
        <div>
          <LoaderStyles />
        </div>
      </ModalContent>
    </ModalOverlay>,
    document.getElementById("root") as HTMLElement
  );
};

export default ModalQRLoading;
