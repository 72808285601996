import React, { useState, useEffect, useRef } from "react";
import {
  BottomNavBarWrapper,
  BottomNavBarExpanded,
  Tab,
  Content,
  Box,
  NumberCircle,
  ArrowIcon,
  BoxAdd,
  AddIcon,
} from "./bottom-navbar.styles";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  getActivePageID,
  getPages,
  setActivePage,
  addPage,
  deletePage,
  duplicatePage,
} from "../../../../core/store/konva-editor/konva-editorSlice";
import ContextMenu from "./components/context-menu";
import { toast } from "sonner";

const BottomNavBar: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [contextMenu, setContextMenu] = useState<{
    visible: boolean;
    x: number;
    y: number;
    pageId: string | null;
  }>({
    visible: false,
    x: 0,
    y: 0,
    pageId: null,
  });

  const sheets = useAppSelector(getPages);
  const activaPage = useAppSelector(getActivePageID);
  const dispatch = useAppDispatch();

  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleBoxClick = (id: string) => {
    dispatch(setActivePage(id));
  };

  const handleAddPage = () => {
    dispatch(addPage());
  };

  const handleContextMenu = (e: React.MouseEvent, pageId: string) => {
    e.preventDefault();
    setContextMenu({ visible: true, x: e.clientX, y: e.clientY, pageId });
  };

  const handleCloseContextMenu = () => {
    setContextMenu({ ...contextMenu, visible: false });
  };

  const handleDeletePage = () => {
    if (contextMenu.pageId) {
      const remainingPages = sheets.filter(
        (page) => page.id !== contextMenu.pageId
      );

      if (remainingPages.length === 0) {
        // Mostrar el toast en lugar de eliminar
        toast.error("No se puede eliminar la última página.");
      } else {
        dispatch(deletePage(contextMenu.pageId));
        handleCloseContextMenu();
      }
    }
  };

  const handleDuplicatePage = () => {
    if (contextMenu.pageId) {
      dispatch(duplicatePage(contextMenu.pageId));
      handleCloseContextMenu();
    }
  };



  return (
    <BottomNavBarWrapper>
      <BottomNavBarExpanded isOpen={isOpen}>
        <Tab isOpen={isOpen} onClick={handleToggle}>
          <ArrowIcon />
        </Tab>
        <Content isOpen={isOpen}>
          {sheets.length > 0 ? (
            sheets.map((page) => (
              <Box
                key={page.id}
                onClick={() => handleBoxClick(page.id)}
                onContextMenu={(e) => handleContextMenu(e, page.id)} // Manejar clic derecho
                isSelected={page.id === activaPage}
              >
                <NumberCircle isSelected={page.id === activaPage}>
                  {page.id}
                </NumberCircle>
              </Box>
            ))
          ) : (
            <p>No hay páginas</p>
          )}
          <BoxAdd onClick={handleAddPage} style={{ cursor: "pointer" }}>
            <AddIcon />
          </BoxAdd>
        </Content>
      </BottomNavBarExpanded>
      <ContextMenu
        x={contextMenu.x}
        y={contextMenu.y}
        visible={contextMenu.visible}
        onClose={handleCloseContextMenu}
        onDelete={handleDeletePage}
        onDuplicate={handleDuplicatePage}
      />
    </BottomNavBarWrapper>
  );
};

export default BottomNavBar;
