import React from "react";
import HeaderSearch from "../../components/header-search/header-search";
import useSearchSheet from "../../utils/hooks/use-search-sheet";
import SectionMax from "../../components/section-max/section-max";

const TermsAndConditions = () => {
  const { handleSetData, handleKeyUp } = useSearchSheet();

  return (
    <>
      <HeaderSearch
        title="Términos y Condiciones"
        handleSetData={handleSetData}
        handleKeyUp={handleKeyUp}
      />
      <SectionMax>
        <h3>
          En concordancia con las regulaciones de Facebook para Apps y sitios
          web, debemos brindarle a los usuarios las instrucciones para eliminar
          sus datos. Si quieres eliminar tu actividad relacionada al Akaya App,
          lo puedes hacer de la siguiente manera.
        </h3>

        <ul>
          <li>
            1. Ingresa a tu cuenta de Facebook y haz clic en “Configuración y
            privacidad”. Después haz clic en “Configuración”.{" "}
          </li>
          <li>
            2. Ve a la sección de “Apps y sitios web”, aquí podrás ver toda tu
            actividad relacionada a aplicaciones y páginas web registradas en tu
            cuenta de Facebook.{" "}
          </li>

          <li>Paso 2</li>

          <li>
            3. Selecciona la casilla correspondiente al Akaya App y haz clic en
            “Eliminar”.{" "}
          </li>

          <li>Paso 3</li>

          <li>
            4. Selecciona las casillas de acuerdo a tu preferencia y haz clic en
            “Eliminar”.{" "}
          </li>

          <li>Paso 4</li>

          <li>
            5. ¡Listo! Eliminaste a Akaya App de tus actividades de manera
            exitosa.
          </li>
        </ul>
      </SectionMax>
    </>
  );
};

export default TermsAndConditions;
