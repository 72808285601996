/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Typography, Modal, Fade, Box, Backdrop, Grid } from "@mui/material";
import {
  getStatusModalQuestionTutorial,
  updateStatusModalQuestionSave,
  updateStatusModalSaveJob,
} from "../../core/store/app-store/appSlice";
import CustomButton from "../custom-button/custom-button";
import { RightArrowAlt } from "@styled-icons/boxicons-regular/RightArrowAlt";
import { useAppSelector, useAppDispatch } from "../../app/hooks";

import { customPalette } from "../../config/theme/theme";
import RuleImg from "../../assets/img/rule_icon.png";
import BookImg from "../../assets/img/book_icon.png";
import styled from "styled-components";

import { Toaster, toast } from "react-hot-toast";
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";
import { usePostUpdateJobByIdMutation } from "../../core/store/konva-editor/konvaAPI";
import {
  getDataSave,
  getInfoJobsSaved,
  resetDataKonva,
} from "../../core/store/konva-editor/konva-editorSlice";
import { useNavigate } from "react-router-dom";

const BoxStyle = styled(Box)`
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 20px 25px;
  outline: none;
  background: white;
  border-radius: 20px;
  overflow: hidden;

  > div:nth-child(1) {
    background: ${customPalette.secondaryColor};
    width: 3px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 20px;
    height: 100%;
  }
`;

const WrapperBookImg = styled.img`
  position: absolute;
  bottom: 10px;
  left: -60px;
  width: 150px;
  opacity: 0.2;
`;
const WrapperRuleImg = styled.img`
  position: absolute;
  top: 0;
  right: -80px;
  width: 200px;
  opacity: 0.2;
`;

const WrapperClose = styled.div`
  position: absolute;
  cursor: pointer;
  height: 20px;
  width: 20px;
  right: 28px;
  top: 20px;

  > svg {
    width: 25px;
    height: 25px;
    color: red;
  }
`;

const ModalQuestionSave: React.FC = () => {
  const [updateCurrentJob, resultUpdateJob] = usePostUpdateJobByIdMutation();
  const isStatus = useAppSelector(getStatusModalQuestionTutorial);
  const dataCurrentJob = useAppSelector(getDataSave);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCloseModal = () => dispatch(updateStatusModalQuestionSave(false));

  const handleChangeModalJob = () => {
    dispatch(updateStatusModalQuestionSave(false));
    dispatch(updateStatusModalSaveJob(true));
  };

  const updateSaveCurrentJob = () => {
    updateCurrentJob(dataCurrentJob)
      .unwrap()
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    if (resultUpdateJob.isSuccess) {
      toast.success("Trabajo actualizado con éxito");
      setTimeout(() => {
        // dispatch(resetDataKonva());
        dispatch(updateStatusModalQuestionSave(false));
        dispatch(updateStatusModalSaveJob(false));
        // navigate("/dashboard/guardados");
        toast.success("Trabajo guardado con éxito");
      }, 1000);
    }
  }, [resultUpdateJob.isSuccess]);

  return (
    <>
      <Toaster />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isStatus}
        onClose={handleCloseModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isStatus}>
          <BoxStyle>
            <div />
            <WrapperBookImg src={BookImg} />
            <WrapperRuleImg src={RuleImg} />
            <WrapperClose
              onClick={() => dispatch(updateStatusModalQuestionSave(false))}
            >
              <CloseOutline />
            </WrapperClose>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginBottom={2}
            >
              <Typography
                variant="h5"
                component="h5"
                fontWeight="600"
                textAlign={"center"}
                color={customPalette.secondaryColor}
              >
                ¡Advertencia!
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginBottom={2}
            >
              <Typography variant="caption" component="label" textAlign="left">
                Estas intentando guardar sobre un trabajo existente, para
                aceptar presiona <strong> Continuar</strong>, de lo contrario,
                presiona <strong> Guardar Nuevo</strong>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              position="relative"
              zIndex={2}
              justifyContent="center"
              alignItems="center"
              flexWrap={"wrap"}
              display={"flex"}
              columnGap={2}
              rowGap={2}
            >
              <CustomButton
                title="Guardar Nuevo"
                style="SECONDARY"
                borderStyle="OUTLINE"
                action={handleChangeModalJob}
                customStyle={`
                  padding: 8px 10px;
                  width: 46%;
                `}
              />
              <CustomButton
                title="Continuar"
                style="SECONDARY"
                borderStyle="NONE"
                Icon={RightArrowAlt}
                action={updateSaveCurrentJob}
                isLoading={resultUpdateJob.isLoading}
                customStyle={`
                  border-color: white;
                  color: white
                  padding: 8px 10px;
                  width: 46%;
                `}
              />
            </Grid>
          </BoxStyle>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalQuestionSave;
