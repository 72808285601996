import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Trash } from "@styled-icons/bootstrap/Trash";
import { Duplicate } from "@styled-icons/ionicons-outline/Duplicate";

const MenuWrapper = styled.div<{ x: number; y: number; visible: boolean }>`
  position: fixed;
  top: ${({ y }) => y - 50}px; // Muestra el menú hacia arriba
  left: ${({ x }) => x}px;
  background: white; // Cambia el color de fondo a blanco
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: ${({ visible }) => (visible ? "block" : "none")};
  z-index: 1000;
  border-radius: 10px; // Añadir bordes redondeados
  overflow: hidden;
`;

const MenuItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  gap: 10px;
  display: flex;
  font-weight: 400;
  &:hover {
    background: #f0f0f0; // Color al pasar el ratón
  }
`;

interface ContextMenuProps {
  x: number;
  y: number;
  visible: boolean;
  onClose: () => void;
  onDelete: () => void;
  onDuplicate: () => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  x,
  y,
  visible,
  onClose,
  onDelete,
  onDuplicate,
}) => {
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose(); // Cierra el menú si se hace clic fuera
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleMenuItemClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Evita que el clic se propague al documento
  };

  return (
    <MenuWrapper ref={menuRef} x={x} y={y} visible={visible}>
      <MenuItem
        onClick={(e) => {
          handleMenuItemClick(e); // Evita que el evento se propague
          onDuplicate();
          onClose();
        }}
      >
        <Duplicate width={20} /> Duplicar
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          handleMenuItemClick(e); // Evita que el evento se propague
          onDelete();
          onClose();
        }}
      >
        <Trash width={20} /> Eliminar
      </MenuItem>
    </MenuWrapper>
  );
};

export default ContextMenu;
