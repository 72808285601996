interface IDataFAQ {
  id: number;
  question: string;
  response: string;
  listSteps: string[];
  paragraph01: string;
  paragraph02: string;
  open: false;
}
export const listFAQ: IDataFAQ[] = [
  {
    id: 1,
    open: false,
    question: "¿Qué sucede si no utilizo todas las descargas de mi plan?",
    response:
      "Las descargas no utilizadas en los planes de pago estarán disponibles por 12 meses después de tu compra, ofreciéndote una gran flexibilidad. Además, si activas otro plan antes de que termine este periodo, cualquier descarga no utilizada se acumulará y la vigencia de estas se extenderá por un año más a partir de la fecha de activación del nuevo plan. Esto te asegura que no perderás tus recursos no utilizados y podrás planificar tus proyectos con tranquilidad. Para el plan gratuito, las descargas se renuevan cada mes, garantizando que siempre dispongas de recursos frescos para tus necesidades.",
    listSteps: [],
    paragraph01: "",
    paragraph02: "",
  },
  {
    id: 2,
    open: false,
    question: "¿Qué tipo de archivos se pueden descargar?",
    response:
      "Puedes descargar todos los tipos de archivos que figuran en la tabla de planes, incluyendo PDF,JPG, PNG y JPEG. Esto te proporciona una amplia gama de opciones para tus proyectos y necesidades.",
    listSteps: [],
    paragraph01: "",
    paragraph02: "",
  },
  {
    id: 3,
    open: false,
    question: "¿Los archivos que se descargan son en alta resolución?",
    response:
      "Sí, todos nuestros archivos están en alta resolución, perfectos para impresiones convencionales. Priorizamos la calidad para asegurar que tus trabajos siempre luzcan profesionales.",
    listSteps: [],
    paragraph01: "",
    paragraph02: "",
  },
  {
    id: 4,
    open: false,
    question: "¿Qué son las tarjetas LamFlex?",
    response:
      "Las tarjetas LamFlex son tarjetas prepago que facilitan la recarga de descargas de láminas en cantidades de 10, 20 o 50. Diseñadas para clientes que requieren un uso limitado de láminas, estas tarjetas ofrecen una alternativa perfecta para aquellos que buscan flexibilidad y control sobre su consumo sin comprometerse a planes de largo plazo. Con las tarjetas LamFlex, obtienes acceso directo a recursos de alta calidad, adaptándose a tus necesidades específicas de proyecto o trabajo.",
    listSteps: [],
    paragraph01: "",
    paragraph02: "",
  },
  {
    id: 5,
    open: false,
    question: "¿Dónde puedo comprar las tarjetas LamFlex?",
    response:
      "Puedes encontrar las tarjetas LamFlex en cualquier librería asociada a nuestro servicio. Busca el logo de LamFlex para garantizar que estás adquiriendo una tarjeta auténtica.",
    listSteps: [],
    paragraph01: "",
    paragraph02: "",
  },
  {
    id: 6,
    open: false,
    question: "¿Cómo activo una tarjeta LamFlex?",
    response: `
    Para activar tu tarjeta LamFlex y empezar a disfrutar de tus descargas, sigue estos pasos detallados
    Encuentra el  Código: Busca en tu tarjeta LamFlex el código único de 6 dígitos preparado para la activación.
    Accede a la Sección de Activación: Puedes hacerlo de varias maneras:
  `,
    listSteps: [
      "Desde la Página de Inicio: Visita nuestra página de inicio donde encontrarás un enlace o botón que te dirigirá a la sección de 'Activar Tarjeta'",
      "A través del Icono Flotante: Mientras navegas en nuestro sitio, usa el icono flotante disponible para acceder rápidamente a la activación.",
      "Enlace Directo: Para una ruta directa, puedes presionar aquí para ser redirigido a la página de activación. (Nota: Sustituye '#' con el enlace real).",
    ],
    paragraph01:
      "Ingresa el Código: En la sección de activación, introduce el código de 6 dígitos encontrado en tu tarjeta.",
    paragraph02:
      "Confirma la Activación: Haz clic en el botón para activar. Recibirás un mensaje confirmando que la tarjeta ha sido activada con éxito, permitiéndote descargar las láminas de inmediato. Siguiendo estos sencillos pasos asegurarás la activación efectiva de tu tarjeta LamFlex, dándote acceso instantáneo a las descargas que necesitas para avanzar en tus proyectos o trabajos creativos.",
  },
  {
    id: 7,
    open: false,
    question:
      "¿Puedo usar el Editor para hacer trabajos con imágenes externas?",
    response:
      "Absolutamente, nuestro Editor te permite importar imágenes externas para que puedas personalizar tus proyectos aún más. Nos encanta ver cómo nuestros usuarios llevan su creatividad al siguiente nivel.",
    listSteps: [],
    paragraph01: "",
    paragraph02: "",
  },
  {
    id: 8,
    open: false,
    question: "¿Existe una aplicación móvil para acceder a los servicios?",
    response:
      "Actualmente, no contamos con una aplicación móvil específica, pero nuestro portal web está completamente adaptado para funcionar en dispositivos móviles. Esto significa que puedes acceder a todos nuestros servicios, incluyendo las descargas y el Editor, directamente desde tu navegador en cualquier smartphone o tablet. Nuestra plataforma optimizada para móviles asegura que tengas una experiencia fluida y accesible, permitiéndote trabajar en tus proyectos o descargar recursos en cualquier momento y lugar, sin perder la calidad ni la funcionalidad. ¡Así que no te preocupes, tu creatividad no tiene límites con nosotros, incluso en movimiento!",
    listSteps: [],
    paragraph01: "",
    paragraph02: "",
  },
  {
    id: 9,
    open: false,
    question: "¿Los planes tienen algún beneficio adicional?",
    response:
      "Además de las descargas, nuestros planes ofrecen acceso a herramientas de edición avanzadas, soporte prioritario y, en el caso del plan más alto, obsequios exclusivos como el Maletín Escolar Premium. ¡Siempre hay más valor en nuestros planes!",
    listSteps: [],
    paragraph01: "",
    paragraph02: "",
  },
  {
    id: 10,
    open: false,
    question: "¿Qué métodos de pago aceptan?",
    response:
      "Aceptamos todos los principales métodos de pago, incluyendo tarjetas de crédito, visa mastercard, plin yape y tarjetas LamFlex. Nuestro objetivo es facilitarte el acceso a nuestros servicios de la manera que te sea más conveniente.",
    listSteps: [],
    paragraph01: "",
    paragraph02: "",
  },
  {
    id: 11,
    open: false,
    question: "¿Ofrecen pruebas gratuitas para los planes de pago?",
    response:
      "No tenemos pruebas gratuitas para los planes de pago, pero nuestro plan gratuito te permite descargar hasta 5 láminas mensuales y acceder a información valiosa, ideal para conocer la calidad de nuestros servicios. Para comenzar con este plan y explorar nuestros recursos, visita nuestra página de inicio y busca la opción para Contratar el plan gratuito. Este acceso sin costo es una excelente forma de descubrir todo lo que podemos ofrecerte antes de optar por un plan de pago.",
    listSteps: [],
    paragraph01: "",
    paragraph02: "",
  },
  {
    id: 12,
    open: false,
    question: "¿Cómo puedo obtener soporte si tengo problemas o preguntas?",
    response:
      "Para asegurarte de que siempre recibas la ayuda que necesitas, nuestro equipo de soporte está disponible 24/7, listo para asistirte con cualquier problema o pregunta que puedas tener. Los usuarios de nuestros planes de pago disfrutan de acceso a soporte prioritario, lo que garantiza respuestas rápidas y eficientes a sus inquietudes. Adicionalmente, ofrecemos la opción de comunicarte directamente con nuestros asesores de servicio a través de WhatsApp, brindándote una vía rápida y cómoda para resolver tus dudas o problemas en tiempo real. Esta flexibilidad en el soporte asegura que, sin importar el canal que elijas, siempre estaremos ahí para ayudarte.",
    listSteps: [],
    paragraph01: "",
    paragraph02: "",
  },
  {
    id: 13,
    open: false,
    question: "¿Se actualizan regularmente las colecciones de imágenes?",
    response:
      "Constantemente añadimos nuevas imágenes a nuestras colecciones para asegurar que siempre tengas acceso a los recursos más frescos y relevantes para tus proyectos.",
    listSteps: [],
    paragraph01: "",
    paragraph02: "",
  },
  {
    id: 14,
    open: false,
    question:
      "¿Puedo sugerir nuevas características o imágenes para el servicio?",
    response:
      "Valoramos mucho la retroalimentación de nuestros usuarios. Si tienes ideas o sugerencias, nos encantaría escucharlas. Tu input nos ayuda a crecer y mejorar.",
    listSteps: [],
    paragraph01: "",
    paragraph02: "",
  },
];
