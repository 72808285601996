/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
  Typography,
  Modal,
  Fade,
  Box,
  Backdrop,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  updateStatusModalCoupon,
  getStatusModalCouponQr,
  updateStatusModalCouponQr,
} from "../../core/store/app-store/appSlice";
import CustomButton from "../custom-button/custom-button";
import { RightArrowAlt } from "@styled-icons/boxicons-regular/RightArrowAlt";
import { useForm } from "react-hook-form";
import { settingsAPP } from "../../config/environments/settings";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { CouponForm, CouponSchema } from "../../core/models/coupon-model";
import { customPalette } from "../../config/theme/theme";
import { yupResolver } from "@hookform/resolvers/yup";
import RuleImg from "../../assets/img/rule_icon.png";
import BookImg from "../../assets/img/book_icon.png";
import LogoImg from "../../assets/img/logo.svg";
import CrearteIconImg from "../../assets/img/crearte_icon.png";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import { usePostRedimirCouponMutation } from "../../core/store/coupon/couponAPI";
import {
  ETemporalActions,
  updateTemporalAction,
} from "../../core/store/temporal/temporalSlice";
import axios from "axios";
import Cookies from "js-cookie";
import { APP_CONSTANS } from "../../constants/app";
import { IAuthData } from "../../core/store/auth/types/auth-types";
import { toast } from "sonner";

const BoxStyle = styled(Box)`
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 450px;
  padding: 20px 25px;
  outline: none;
  background: white;
  border-radius: 20px;
  overflow: hidden;

  > div:nth-child(1) {
    background: ${customPalette.secondaryColor};
    width: 3px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 20px;
    height: 100%;
  }
`;
const GridCaptcha = styled(Grid)`
  > div div div:nth-child(1) {
    margin: auto;
  }
`;

const WrapperBookImg = styled.img`
  position: absolute;
  bottom: 10px;
  left: -60px;
  width: 150px;
  opacity: 0.2;
`;
const WrapperRuleImg = styled.img`
  position: absolute;
  top: 0;
  right: -80px;
  width: 200px;
  opacity: 0.2;
`;

const FormContainer = styled.div`
> div:nth-child(1), div:nth-child(2){
  justify-content: center;
    flex-direction: column;
    align-items: left;
    display: flex;
    padding 8px 15px;
    
    > input {
      box-shadow: 0px 4px 8px 5px rgba(198, 198, 198, 0.25);
      border: 1px solid #001C46;
      background-color: white;
      border-radius: 12px;
      padding: 8px 14px;
      outline: none;
      width: 100%;
    }
  }
  
  > div:nth-child(3){
    text-align: right;
    padding-right: 15px;
  }
`;
const ContainerEmailStyle = styled.div<{ errorStyle: boolean }>`
  > input {
    border: ${(p) =>
      p.errorStyle ? "1px solid red !important" : "1px solid #001C46;"};
  }
`;
const ErrorMessage = styled.span`
  font-size: 10px;
  color: red;
  margin-top: 6px;
`;

const CodeContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;

  input {
    width: 35px;
    height: 42px;
    margin: 0 5px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #20a7ad;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(198, 198, 198, 0.25);

    &:focus {
      box-shadow: 0 0 5px #e1d86d;
      transform: scale(1.18);
      border-color: #ffec65;
      transition: 0.5;
      outline: none;
    }

    &:valid {
      background-color: white;
    }
  }

  > span {
    font-size: 30px;
  }
`;

const CouponCodeInput: React.FC<{
  onChange: (value: string) => void;
  codeErrors: any;
}> = ({ onChange, codeErrors }) => {
  const [code, setCode] = React.useState(Array(8).fill(""));

  const handleChange = (value: string, index: number) => {
    const newCode = [...code];
    newCode[index] = value;

    // Mover al siguiente input
    if (value && index < code.length - 1) {
      const nextInput = document.getElementById(`input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }

    setCode(newCode);
    onChange(newCode.join(""));
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace" && index > 0 && !code[index]) {
      const previousInput = document.getElementById(`input-${index - 1}`);
      if (previousInput) {
        previousInput.focus();
      }
    }
  };

  const handlePaste = (
    e: React.ClipboardEvent<HTMLInputElement>,
    index: number
  ) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, 8).split("");
    const newCode = [...code];

    pasteData.forEach((char, i) => {
      if (index + i < code.length) newCode[index + i] = char;
    });

    setCode(newCode);
    onChange(newCode.join(""));
    // Enfocar el último input pegado
    const focusInput = document.getElementById(
      `input-${Math.min(index + pasteData.length, code.length - 1)}`
    );
    if (focusInput) {
      focusInput.focus();
    }
  };

  return (
    <CodeContainer>
      {code.map((char, index) => (
        <React.Fragment key={index}>
          <input
            id={`input-${index}`}
            type="text"
            maxLength={1}
            value={char}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={(e) => handlePaste(e, index)}
          />
          {index === 3 && <span>-</span>}{" "}
          {/* Agregar guion después del cuarto input */}
        </React.Fragment>
      ))}
    </CodeContainer>
  );
};

const ModalCouponQr: React.FC = () => {
  const [isLoadingRedemtionCoupon, setIsLoadingRedemtionCoupon] =
    React.useState(false);

  const isStatus = useAppSelector(getStatusModalCouponQr);
  const dispatch = useAppDispatch();

  const methods = useForm<CouponForm>({
    resolver: yupResolver(CouponSchema),
    defaultValues: {
      code: "",
      recaptcha: "",
    },
  });

  const {
    handleSubmit: submitWrapper,
    formState: { errors },
    register,
    setValue,
  } = methods;

  const handleSubmit = async (data: any) => {
    setIsLoadingRedemtionCoupon(true);
    try {
      const dataUser = Cookies.get(APP_CONSTANS.AUTH_USER_DATA);
      if (dataUser != null && dataUser != undefined) {
        const user = JSON.parse(dataUser) as IAuthData;
        const response = await axios.post(
          `${settingsAPP.api.coupon}/coupon-qr/canjear`,
          {
            code: data.code,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setIsLoadingRedemtionCoupon(false);
        toast.success(response.data.message);
        dispatch(updateStatusModalCouponQr(false));
      }
    } catch (error: any) {
      setIsLoadingRedemtionCoupon(false);
      toast.error(error.response.data.message);
    }
  };

  function onChange(value: any) {
    if (value != "") {
      setValue("recaptcha", value);
    }
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isStatus}
        onClose={() => {
          dispatch(updateStatusModalCouponQr(false));
          dispatch(updateTemporalAction(ETemporalActions.NO_ACTION));
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isStatus}>
          <BoxStyle>
            <div />
            <WrapperBookImg src={BookImg} />
            <WrapperRuleImg src={RuleImg} />
            <Grid container>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={1}
                marginBottom={1}
              >
                <Box
                  component="img"
                  sx={{
                    padding: "4px",
                    maxWidth: { xs: 160, sm: 140, md: 160 },
                    margin: "auto",
                  }}
                  alt="Logo de Elaminas"
                  src={LogoImg}
                />
                <Box
                  component="img"
                  sx={{
                    padding: "4px",
                    maxWidth: { xs: 140, sm: 120, md: 120 },
                    margin: "auto",
                  }}
                  alt="Logo de Elaminas"
                  src={CrearteIconImg}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              flexDirection={"column"}
              marginBottom={2}
            >
              <Typography
                variant="h5"
                component="h5"
                fontWeight="600"
                color={customPalette.primaryColor}
              >
                ¡ Crearte te premia !
              </Typography>
              <Typography
                variant="h5"
                component="h5"
                fontWeight="600"
                fontSize={18}
                color={customPalette.secondaryColor}
              >
                Canjea tu cupón aquí
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormContainer>
                <ContainerEmailStyle
                  errorStyle={!!(errors.code as any)?.message}
                >
                  {/* <Typography
                    variant="caption"
                    component="label"
                    textAlign="left"
                  >
                    Código de cupón
                  </Typography>
                  <input
                    placeholder="Código de cupón"
                    type="email"
                    required
                    {...register("code")}
                  />
                  {!!(errors.code as any)?.message && (
                    <ErrorMessage>{errors?.code?.message}</ErrorMessage>
                  )} */}
                  <CouponCodeInput
                    onChange={(value) => setValue("code", value)}
                    codeErrors={errors.code}
                  />
                  {!!(errors.code as any)?.message && (
                    <ErrorMessage>{errors?.code?.message}</ErrorMessage>
                  )}
                </ContainerEmailStyle>
              </FormContainer>
            </Grid>
            {/* <Grid item xs={12}>
              <CouponCodeInput
                onChange={(value) => setValue("code", value)}
                codeErrors={errors.code}
              />
              {!!(errors.code as any)?.message && (
                <ErrorMessage>{errors?.code?.message}</ErrorMessage>
              )}
            </Grid> */}
            <GridCaptcha
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              marginTop={3}
              marginBottom={3}
              textAlign={"center"}
            >
              <ReCAPTCHA
                sitekey={settingsAPP.app.recaptchaKey}
                onChange={onChange}
              />
              {!!(errors.recaptcha as any)?.message && (
                <ErrorMessage>{errors?.recaptcha?.message}</ErrorMessage>
              )}
            </GridCaptcha>
            <Grid item xs={12} justifyContent="center" alignItems="center">
              <CustomButton
                title="Canjear"
                style="SECONDARY"
                borderStyle="NONE"
                Icon={RightArrowAlt}
                action={submitWrapper(handleSubmit)}
                isLoading={isLoadingRedemtionCoupon}
                customStyle={`
                  border-color: white;
                  color: white
                  padding: 8px 10px;
                `}
              />
            </Grid>
          </BoxStyle>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalCouponQr;
