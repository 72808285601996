import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import Cookies from "js-cookie";

export enum ETemporalActions {
  TEMPORAL_ACTION_KEY = "TEMPORAL_ACTION_KEY",
  NO_ACTION = "NO_ACTION",
  OPEN_COUPON = "OPEN_COUPON",
  OPEN_COUPON_QR = "OPEN_COUPON_QR",
  OPEN_PAYMENT = "OPEN_PAYMENT",
  OPEN_LOGIN = "OPEN_LOGIN",
  OPEN_REGISTER = "OPEN_REGISTER",
  START_COUPON_REDEMPTION = "START_COUPON_REDEMPTION",
  NULL_ACTION_ME = "NULL_ACTION_ME",
}

export interface LaminasState {
  isLoadingTemporalAction: boolean;
  temporalAction: ETemporalActions;
  redirect: string;
}

const initialState: LaminasState = {
  isLoadingTemporalAction: false,
  temporalAction: ETemporalActions.NO_ACTION,
  redirect: "",
};

export const temporalSlice = createSlice({
  name: "temporal",
  initialState,
  reducers: {
    updateIsLoadingTemporalAction: (state, action: PayloadAction<boolean>) => {
      state.isLoadingTemporalAction = action.payload;
    },
    updateTemporalAction: (state, action: PayloadAction<ETemporalActions>) => {
      Cookies.set(ETemporalActions.TEMPORAL_ACTION_KEY, action.payload);
      localStorage.setItem(
        ETemporalActions.TEMPORAL_ACTION_KEY,
        action.payload
      );
      state.temporalAction = action.payload;
    },
  },
});

export const { updateTemporalAction, updateIsLoadingTemporalAction } =
  temporalSlice.actions;

export const getTemporalAction = (state: RootState) =>
  state.temporal.temporalAction;
export const getIsLoadingTemporalAction = (state: RootState) =>
  state.temporal.isLoadingTemporalAction;

export default temporalSlice.reducer;
